import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import queryString from "query-string";

import PropertySupport from "../../content/support/property";

const PropertySupportPage = ({ data }) => {
	const faqs = convertNodes(get(data, "allContentfulPage"));
	const videos = convertNodes(get(data, "allContentfulHelpVideo")).map(
		video => ({
			...video,
			video: {
				id: queryString.parse(video.videoUrl.split("?")[1]).v,
				title: video.title
			}
		})
	);

	const propertyFaqs = faqs.filter(f =>
		f.title
			.trim()
			.toLowerCase()
			.includes("propert")
	);

	const propertyVideos = videos.filter(v =>
		v.title
			.trim()
			.toLowerCase()
			.includes("propert")
	);

	return (
		<PropertySupport {...{ videos: propertyVideos, faqs: propertyFaqs }} />
	);
};

function convertNodes(all) {
	const nodes = get(all, "edges");
	if (!nodes) return [];
	return nodes.map(n => n.node);
}

export default PropertySupportPage;

export const pageQuery = graphql`
	query DeedSupportQuery {
		allContentfulPage(
			filter: { pageType: { eq: "FAQ" } }
			sort: { fields: [title], order: ASC }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}
		allContentfulHelpVideo {
			edges {
				node {
					id
					title
					videoUrl
				}
			}
		}
	}
`;
